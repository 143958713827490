<template>
		<div class="login">
			<img src="../assets/logo.png"  class="logo-img" />
			<div class="login-txt">钱呈无忧远程监控平台</div>
			<div class="login-form">
				<div class="right-box">
					<div class="tabs">
						<div class="active">账户密码登录</div>
						<span class="icon"></span>
					</div>
					<div class="inputs">
						<div>
							<img src="../assets/ic1.png">
							<input type="text" v-model="admin_name" placeholder="请输入账号" />
						</div>
						<div>
							<img src="../assets/ic2.png">
							<input type="password" v-model="password" placeholder="请输入密码" />
						</div>
					</div>
					<div class="check">
						<div class="left">
							<a-checkbox> <span style="color:#000000;font-size: 14px;">下次自动登录</span>  </a-checkbox>
						</div>
					</div>
					<div class="sub" @click="login">登录</div>
					<div class="registered">
		
					</div>
				</div>
			</div>
		</div>
</template>

<script>
	// @ is an alias to /src
	import {
		mapActions
	} from "vuex";
	import http from '../http'
	export default {
		name: "login",
		data() {
			return {
				admin_name: '',
				password: ''
			}
		},
		created() {
			let that = this;
			document.onkeydown = function(e) {
				e = window.event || e;
				if ((that.$route.path == '/login' && e.code == 'Enter' || e.code == 'enter')) {
					that.login()
				}
			}
		},
		methods: {
			registered() {
				this.$router.push({
					path: '/registered'
				})
			},
			login() {
				if (this.admin_name == '') {
					this.$message.warning('请输入账号')
					return
				}
				if (this.password == '') {
					this.$message.warning('请输入密码')
					return
				}
				http({
					method: 'post',
					url: '/api.admin.login/',
					data: {
						password: this.password,
						admin_name: this.admin_name
					}
				}).then((res) => {
					if (res.code == '200') {
						localStorage.setItem('username', res.data.admin_name)
						localStorage.setItem('token', res.data.token)
						localStorage.setItem('authority', JSON.stringify(res.data.authority))
						let authority = []
						res.data.authority.forEach((v) => {
							if (!v.list) {
								v.list = []
							}
							v.list.forEach((vi) => {
								authority.push(vi.router)
							})
							if (v.router == '/home') {
								authority.push(v.router)
							}
						})
						localStorage.setItem('authorityList', JSON.stringify(authority))
						this.$message.success('登录成功');
						this.$router.push({
							path: '/home'
						})
					} else {
						this.$message.error(res.message);
					}
				})

			},
			password() {
				this.$router.push({
					path: '/password'
				})
			},
			...mapActions({
				setTypes: "user/setTypes",
			}),
		}
	};
</script>

<style lang="less" scoped>
	.login {
		background-image: url(../assets/bg.png);
		height: 100vh;
		overflow: hidden;
		.login-form {
			width: 410px;
			height: 430px;
			background: #FFFFFF;
			border-radius: 10px;
			position: absolute;
			left: 50%;
			top: 289px;

			.leftimg {
				width: 498px;
				height: 570px;
			}

			.right-box {
				width: 410px;
				position: relative;

				.tabs {
					justify-content: space-between;
					position: relative;
					margin-bottom: 50px;
					
					div {
						font-size: 20px;
						font-family: PingFang SC;
						font-weight: 600;
						text-align: center;
						color: #000000;
						width: 100%;
						line-height: 20px;
						padding-top: 39px;
						padding-bottom: 12px;
					}

					.icon {
						width: 32px;
						height: 5px;
						background: #3E88F7;
						border-radius: 3px;
						margin: 0 auto;
						display: block;
					}
				}

				.inputs {
					margin-bottom: 40px;
					padding-top: 6px;
					width: 339px;
					margin: 0 auto;
					
					div {
						border-bottom: 1px solid #E5E5E5;
						height: 30px;
						display: flex;
						img {
							width: 25px;
							height: 25px;
							margin-right: 9px;
						}
						margin-bottom: 41px;
						input {
							width: 100%;
							height: 25px;
							
							font-size: 20px;
							font-weight: 400;
							color: #919191;
							line-height: 25px;
							border: none;
							outline: none;
							
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #000000;
							line-height: 36px;
						}
					}

				}

				.check {
					margin-bottom: 37px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 37px;

					.left {
						font-size: 16px;
						font-weight: 400;
						color: #9a9da4;
						line-height: 22px;
					}
					
					input{
						transform: scale(1.3);
					}

					.right {
						font-size: 16px;
						font-weight: 400;
						color: #9a9da4;
						line-height: 22px;
						cursor: pointer;
					}
				}

				.sub {
					width: 340px;
					height: 50px;
					background: #3E88F7;
					border-radius: 3px;
					margin: 0 auto;
					cursor: pointer;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 50px;
					text-align: center;
					&:hover {
						background-color: rgba(76, 132, 255, 0.71);
					}
				}

				.registered {
					position: absolute;
					bottom: 28px;
					left: 0px;
					width: 100%;
					text-align: center;
					cursor: pointer;

					&:hover {
						span {
							opacity: 0.8;
						}
					}

					span {
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #0a1f30;
						line-height: 22px;
						margin: 0 10px;
					}
				}
			}
		}
		
		.logo-img {
			position: absolute;
			top: 60px;
			left: 300px;
		}
		.login-txt {
			position: absolute;
			top: 60px;
			left: 350px;
			font-size: 26px;
			font-weight: bold;
			line-height: 40px;
			color: #000;
		}

		input::-webkit-input-placeholder {
			/* WebKit browsers */
			color: #919191;
		}

		input:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: #919191;
		}

		input::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: #919191;
		}

		input::-ms-input-placeholder {
			/* Internet Explorer 10+ */
			color: #919191;
		}
		
		
		
	}
	
	@media screen and (max-width: 1400px) {
	    .login-img1 {
	    	    width: 413px;
	    	    height: 364px;
	    	    position: absolute;
	    	    left: 20.43% !important;
	    	    top: 62% !important;
	    	    transform: translateY(-53%);
	    }
		.login-form {
			width: 410px;
			height: 430px;
			background: #FFFFFF;
			border-radius: 10px;
			position: absolute;
			left: 50%;
			top: 60% !important;
			transform: translateY(-50%);
		}
	}
	
</style>
